import {ItemStorage, localStorage} from "./localStorage";

/**
 * Concatena la ruta base a un path dado.
 * @param path
 */
export const route = (path: string): string => {
   const basePath = process.env.REACT_APP_ROOT_API?.replace(/[/]+$/, '');
   return `${basePath}/${path}`;
}

/**
 * Se realiza una extracción de fetch para proveer de por defecto el Bearer Token
 * @param url
 * @param options
 * @constructor
 */
export const ApiFetch = async <T extends unknown>(url: string, options: RequestInit = {}) => {
   const _BearerTocken: string = String(localStorage.getItem(ItemStorage.tokenUser));
   const defaultOptions: RequestInit = {
      ...options,
      cache: 'no-cache',
      headers: new Headers({
         ...options?.headers || {},
         'Authorization': `Basic ${_BearerTocken}`,
         'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUBSCRIPTION_KEY || ''
      })
   }
   const response = await fetch(url, defaultOptions)
   if (!response.ok) throw new Error(response.statusText);
   const ContentType = response.headers.get('Content-Type') || 'application/json';
   if (ContentType.includes('text/plain'))
      return (await response.text()) as T
   return (await response.json()) as T
}

/**
 * Realiza la misma acción de ApiFetch forzando el uso del método GET
 * @param url
 * @param data
 * @param options
 * @constructor
 */
export const ApiFetchGet = async <T extends unknown>(url: string, data: any, options: RequestInit = {}) => {
   const postOptions: RequestInit = {
      method: 'GET',
      body: JSON.stringify(data)
   }
   return ApiFetch<T>(url, {...options, ...postOptions});
}
