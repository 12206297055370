import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import parse from 'html-react-parser';
import moment from "moment";
import { Fragment } from "react";
import ReactJsonViewCompare from 'react-json-view-compare';
import { IAuditoria } from "../../../types";
import MyAccordion from "../MyAccordion";

/**
 * Componente para listar elementos
 * @param title
 * @param items
 */
const ListOfAuditorias = ({ title, items }) => {

  function getJson(stringJson: string) {
    try {
      return JSON.parse(stringJson.replace('\\', ''))
    } catch (error) {
      return {}
    }
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {items?.map((auditoria: IAuditoria) => {
        return (<div key={auditoria.rowKey}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={"Tabla afectada: " + parse(auditoria.tabla)}
              secondary={<>
                <p>Codigo Transaccional: {auditoria.idRegistro}</p>
                <Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {auditoria.fecha && <p>{moment(auditoria.fecha).format('DD-MM-YYYY')}</p>}
                    {"Evento: " + parse(auditoria.tipo)}
                  </Typography>
                  <MyAccordion title={"Cambios Efectuados"} content={
                    <ReactJsonViewCompare oldData={getJson(auditoria.objectoAnterior)} newData={getJson(auditoria.objectoActual)} />
                  } />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    <p>{parse(auditoria.comparacion)}</p>
                  </Typography>
                </Fragment>
              </>
              }
            />
          </ListItem>
          <Divider component="li" />
        </div>)
      })}
    </List>
  )
}

export default ListOfAuditorias