import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { INotificaciones } from "../types";
import ListOfAlertas from "./custom/List/ListOfAlertas";

/**
 * Genera un acordeón con la lista de parámetros html
 * @constructor
 */
const Alertas: React.FC = () => {

   /**
    * Hooks
    */
   const { data, isLoading, error } = useFetch<INotificaciones[]>(route(AppConstans.URL.ALERTAS));

   /**
    * Render Principal
    */
   return <>
      {isLoading && <LoaderSpinner loading />}
      {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
      {!isLoading &&

         <ListOfAlertas title={"Alertas"} items={data} />

      }
   </>;

};

export default Alertas;
