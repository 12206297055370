import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";

import ErrorBoundary from "./components/withErrorBoundary";
import Alertas from "./components/Alertas";
import Auditoria from "./components/Auditoria";
import LogNegocio from "./components/LogNegocio";

/**
 * Types
 */
 interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

/**
 * App principal
 * @constructor
 */
const App = () => {

  /**
   * Estados
   */
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (<Box style={{ padding: "5px" }}>{children}</Box>)}
      </div>
    );
  };

  return <>
    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Alertas" />
      <Tab label="Auditoria" />
      <Tab label="Logs de Negocio" />
    </Tabs>
    <TabPanel value={value} index={0}>
      <ErrorBoundary><div style={{ overflowY: 'scroll', height: '80vh' }}><Alertas /></div></ErrorBoundary>
    </TabPanel>
    <TabPanel value={value} index={1}>
      <ErrorBoundary><div style={{ overflowY: 'scroll', height: '80vh' }}><Auditoria /></div></ErrorBoundary>
    </TabPanel>
    <TabPanel value={value} index={2}>
      <ErrorBoundary><div style={{ overflowY: 'scroll', height: '80vh' }}><LogNegocio /></div></ErrorBoundary>
    </TabPanel>
  </>;
};

export default App;
