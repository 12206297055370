import { Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import parse from 'html-react-parser';
import moment from "moment";
import { Fragment } from "react";
import { ILogNegocio } from "../../../types";

/**
 * Componente para listar elementos
 * @param title
 * @param items
 */
const ListOfLogsNegocio = ({ title, items }) => {

  return (<div className={"content"}>
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {items?.map((logNegocio: ILogNegocio) => {
        return (<div key={logNegocio.rowKey}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={parse(logNegocio.textTitle)}
              secondary={<>
                <p>Codigo Transaccional: {logNegocio.codeTransaccional}</p>
                <Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {logNegocio.fecha && <p>{moment(logNegocio.fecha).format('DD-MM-YYYY')}</p>}
                    {parse(logNegocio.tipoError)}
                  </Typography>
                  {" - " + logNegocio.descripcionError ?? ""}
                </Fragment>
              </>
              }
            />
          </ListItem>
          <Divider component="li" />
        </div>)
      })}
    </List>
  </div>
  )
}

export default ListOfLogsNegocio