import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { IAuditoria, ILogNegocio } from "../types";
import ListOfLogsNegocio from "./custom/List/ListOfLogsNegocio";

/**
 * Genera un acordeón con la lista de parámetros html
 * @constructor
 */
const LogNegocio: React.FC = () => {

    /**
     * Hooks
     */
    const { data, isLoading, error } = useFetch<ILogNegocio[]>(route(AppConstans.URL.LOGNEGOCIO));

    /**
     * Render Principal
     */
    return <>
        {isLoading && <LoaderSpinner loading />}
        {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
        {!isLoading &&

            <ListOfLogsNegocio title={"Logs de Negocio"} items={data} />

        }
    </>;

};

export default LogNegocio;
