import React from "react";
import { Alert } from "@mui/material";

import useFetch from "../hooks/useFetch";
import { route } from "../helpers";
import { AppConstans } from "../helpers";
import { LoaderSpinner } from "./LoaderSpinner";
import { IAuditoria } from "../types";
import ListOfAuditorias from "./custom/List/ListOfAuditorias";

/**
 * Genera un acordeón con la lista de parámetros html
 * @constructor
 */
const Auditoria: React.FC = () => {

    /**
     * Hooks
     */
    const { data, isLoading, error } = useFetch<IAuditoria[]>(route(AppConstans.URL.AUDITORIA));

    /**
     * Render Principal
     */
    return <>
        {isLoading && <LoaderSpinner loading />}
        {error && <Alert severity="error">Error al intentar cargar el contenido del componente.</Alert>}
        {!isLoading &&

            <ListOfAuditorias title={"Auditoria"} items={data} />

        }
    </>;

};

export default Auditoria;
