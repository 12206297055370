import { Chip, Divider, List, ListItem, ListItemText, Typography } from "@mui/material"
import parse from 'html-react-parser';
import moment from 'moment'

/**
 * Componente para listar elementos
 * @param title
 * @param items
 */
const ListOfAlertas = ({ title, items }) => {

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {items?.map(notification => {
        return (<div key={notification.rowKey}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={parse(notification.mensaje)}
              secondary={<>
                <p>Codigo Transaccional: {notification.codeTransaccional}</p>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {moment(notification.fecha).format('DD-MM-YYYY')}
                  </Typography>
                  <Chip icon={<i className="bi bi-clock" style={{ color: '#204d99' }}></i>} variant="outlined" size="small" label={moment(notification.fecha).format('hh:mm:ss a')} />
                </div>
              </>
              }
            />
          </ListItem>
          <Divider component="li" />
        </div>)
      })}
    </List>
  )
}

export default ListOfAlertas